import React from "react";
import videoEN from "../../../assets/video/SearchVideoEN.mp4";
import videoES from "../../../assets/video/SearchVideoES.mp4";
import videoPR from "../../../assets/video/SearchVideoPR.mp4";
import videomobileEN from "../../../assets/video/SearchVideoMobileEN.mp4";
import videomobileES from "../../../assets/video/SearchVideoMobileES.mp4";
import videomobilePR from "../../../assets/video/SearchVideoMobilePR.mp4";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useI18next } from "gatsby-plugin-react-i18next";

function handleVideo(val) {
  switch (val) {
    case "en":
      return videoEN;
    case "es":
      return videoES;
    case "pr":
      return videoPR;
    default:
      return videoEN;
  }
}

function handleVideoMobile(val) {
  switch (val) {
    case "en":
      return videomobileEN;
    case "es":
      return videomobileES;
    case "pr":
      return videomobilePR;
    default:
      return videomobileEN;
  }
}
const SearchVideo = () => {
  const breakpoints = useBreakpoint();
  const { language } = useI18next();

  return (
    <section className='container'>      
      <div className='row'>
        <div className='col-md-12'>
          <video
            style={{
              height: "100%",
              margin: "auto",
              width: breakpoints.md ? "90%" : "90%",
            }}
            src={
              breakpoints.md
                ? handleVideoMobile(language)
                : handleVideo(language)
            }
            autoPlay
            loop
            muted
            playsInline
          >
            Tu navegador no admite el elemento <code>video</code>.
          </video>
        </div>
      </div>      
    </section>
  );
};

export default SearchVideo;
